<template>
  <div id="RT" style="width:794px;height:1123px;border:1px solid #000000;">
    <div style="margin:45px 57px">
      <el-row class="title">
        <el-col :span="6">
          <div class="blank"></div>
        </el-col>
        <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;">
                        心狗远程诊疗报告
                    </span></el-col>
        <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                               src="../../../../public/img/logo_xingou.png" /></el-col>
      </el-row>
      <!-- 绘制双横线 -->
      <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
      <el-row>
        <div align="center" style="font-size: 20px; ">
          诊疗信息
        </div>
      </el-row>
      <el-row class="info">
        <el-col :span="6"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        姓名：{{ userInfo.realName }}</span></el-col>
        <el-col :span="6"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        性别：{{ userInfo.gender === 1 || userInfo.gender === '1' ? '男' : '女' }}</span></el-col>
        <el-col :span="6"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        年龄：{{ userInfo.age }}</span></el-col>
        <el-col :span="6"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        医生：{{ docInfo.realName }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        预约时间：{{ recordDiagnosis.orderTime }} </span></el-col>
        <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        结束时间：{{ recordDiagnosis.diagnosisTime }} </span></el-col>
        <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        基础病信息：{{ userInfo.disease }} </span></el-col>
      </el-row>
      <!-- 绘制单横线 -->
      <hr style="border-top: 2px solid gray;" />
      <div align="center" style="font-size: 20px; ">
        心狗诊疗记录
      </div>
      <div>
        <!--        <el-descriptions title="基本概况" :column="1" border :labelStyle="labelStyle">-->
        <!--          <el-descriptions-item v-for="item in GeneralInfo" :key="item.title" :label="item.title">-->
        <!--            {{ item.number + item.unit }}-->
        <!--          </el-descriptions-item>-->
        <!--        </el-descriptions>-->
        <!--        <br>-->
        <!--        <div>在心脏监护过程中，心狗云医检测到以下异常：</div>-->
        <!--        <el-table :data="AbnormalList" @cell-dblclick="cellDblClick" :show-header=false>-->
        <!--          <el-table-column type="index"></el-table-column>-->
        <!--          <el-table-column prop="disease" label="结论">-->
        <!--            <template slot-scope="scope">-->
        <!--                            <span v-if="!scope.row.isEditCell" style="cursor:pointer">{{ scope.row.disease }}-->
        <!--                            </span>-->
        <!--              <el-input v-if="scope.row.isEditCell" v-model="scope.row.disease" placeholder="结论"-->
        <!--                        @blur="cellBlur(scope.row, scope.column)" style="width:70%" ref="DiseaseRef"></el-input>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--        </el-table>-->

        <el-descriptions title="" :column="1" border :labelStyle="labelStyle">
          <el-descriptions-item label="主诉">
            {{ recordDiagnosis.symptoms }}
          </el-descriptions-item>
          <el-descriptions-item label="诊断结论">
            {{ recordDiagnosis.diagnosisConclusion }}
          </el-descriptions-item>
          <el-descriptions-item label="治疗建议">
            {{ recordDiagnosis.diagnosisNotes }}
          </el-descriptions-item>
        </el-descriptions>
        <br>
        <div align="right">
          签字：
          <img style="width:30%" :src="Signature" alt="" v-if="Signature">
        </div>
        <div align="right">
          报告开具时间：{{ recordDiagnosis.diagnosisTime }}
        </div>
        <div align="right" style="font-size: 10px;">
          *医生建议及诊断结果仅供参考
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreatReport",
  components: {},
  data() {
    //这里存放数据
    return {
      report: {
        realName: "一二三",
        gender: 1,
        age:23,
        docName: "心狗医生",
        orderTime: "2023-03-28-14:00",
        finishTime:"2023-03-28-14:17",
        basicDisease: "无",
        remark: "心悸3天，无胸痛",
        conclusion:"植物神经紊乱，无心率不齐",
        suggestion:"规律作息，放松心情，保持运动，清淡饮食",
        createDate:"2023-03-28"
      },
      userInfo: {},
      docInfo: {},
      recordDiagnosis:{},

      uid: '',

      GeneralInfo: [
        {
          title: "静态20s心电:",
          number: 0,
          unit: '次'
        },
        {
          title: "动态测量:",
          number: 0,
          unit: '次'
        },
        {
          title: "心脏监护覆盖天数:",
          number: 0,
          unit: '天'
        },
        {
          title: "时长共计:",
          number: 0,
          mounted: function () {
            this.number = this.usageCount.staticEcg
          },
          unit: '分钟'
        },
        {
          title: "定期监护习惯:",
          number: 0,
          mounted: function () {
            this.number = this.usageCount.staticEcg
          },
          unit: ''
        },
      ],
      AbnormalList: [
        // {
        //     disease: '疾病1'
        // },
        // {
        //     disease: '疾病2'
        // },
      ],
      labelStyle: {
        'width': '30%',
        'color': '#606266'
      },
      Signature: '',
      SignatureVisible: false,
      docId: '',
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    getTreatReport(Obj){
      this.$http({
        url:this.$http.adornUrl('/doctor/recorddiagnosis/getDiagnosisReport'),
        method: 'post',
        params: this.$http.adornParams({
          diagnosisId: Obj
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("获取成功")
          this.userInfo = data.userInfo
          this.docInfo = data.docInfo
          this.recordDiagnosis = data.recordDiagnosis
        }else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    // 接口7：查询用户最新一次心电报告
    getLastReport() {
      this.$http({
        url: this.$http.adornUrl('/doctor/vipreport/getLastReport'),
        method: 'post',
        params: this.$http.adornParams({
          uid: this.uid
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.report = data.report
          // 基本信息统计
          this.usageCount = JSON.parse(data.report.usageCount)
          console.log(this.usageCount)
          for (let i = 0; i < this.GeneralInfo.length; i++) {
            let GeneralInfo = this.GeneralInfo[i]
            let sequence = ['staticEcg', 'dynamicEcg', 'days', 'duration', 'style']
            GeneralInfo.number = this.usageCount[sequence[i]]
          }
          // 异常项统计
          let result = JSON.parse(data.report.abnormalCondition)  //数组列表形式，若不是，则加JSON.parse进行转换
          if (result) {
            let uniqueResult = Array.from(new Set(result)) // 去重
            var arr = []
            for (let i = 0; i < uniqueResult.length; i++) {
              let value = uniqueResult[i].toString()
              let obj = {
                //属性1
                disease: value
              };
              arr.push(obj);
            }
            this.AbnormalList = arr
          } else {
            this.AbnormalList = []
          }

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    // 双击编辑
    cellDblClick(row, column) {
      if (column.property == "disease") {
        this.$set(row, "isEditCell", true);
      }
      this.AbnormalList = this.AbnormalList.filter(item => {
        return item;
      }) //视图刷新
      console.log(column.property)
      this.$nextTick(() => {
        this.$refs.DiseaseRef.focus(); // 视图出现后使input获取焦点
      })

    },
    // 可以编辑框失去焦点
    cellBlur(row, column) {
      row.isEditCell = false;
      this.$set(row, 'isEditCell', false);
    },

    // 接口10：医生查询个人签名
    getSignature() {
      this.$http({
        url: this.$http.adornUrl('/doctor/doctorinfo/getSignature'),
        method: 'get',
        params: this.$http.adornParams({ docId: this.$route.query.docId })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.Signature = data.signature
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTreatReport(this.$route.query.diagnosisId)
    console.log("看看传参")
    console.log(this.$route.query.diagnosisId)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getSignature();
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped>
.blank {
  border-radius: 4px;
  height: 18px;
}
.el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 6px 10px;
}
</style>